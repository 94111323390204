import React, { useState } from "react"
import { useTranslation, Trans, useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Contact() {
  const { languages, originalPath } = useI18next() // Get language info
  const { t, i18n } = useTranslation() // Get translation info

  const siteUrl = "https://arcanode.io/contact/"

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const response = await fetch("/sendEmail.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      })
      const result = await response.json()

      if (response.ok) {
        console.log("Email sent:", result)
        alert(t("contact.form.successMessage"))
      } else {
        console.error("Error:", result.message)
        alert(t("contact.form.errorMessage")) // Add this to your translation files
      }
    } catch (error) {
      console.error("Network error:", error)
      alert(t("contact.form.errorMessage")) // Add this to your translation files
    }
  }

  return (
    <>
      <GatsbySeo
        title={t("contact.seo.title", "Contact Us")}
        description={t(
          "contact.seo.description",
          "Arcanode provides expertise and innovation for retail, wholesalers, and IT companies; it’s the secure and high-performing solution that empowers businesses without compromising on cost and quality."
        )}
        canonical={`${siteUrl}/${i18n.language}${originalPath}`}
        openGraph={{
          url: `${siteUrl}/${i18n.language}${originalPath}`,
          title: t("contact.seo.title", "Contact Us"),
          description: t(
            "contact.seo.description",
            "We are the compass for your business needs"
          ),
          images: [
            {
              url: `${siteUrl}/icons/icon-512x512.png`,
              width: 512,
              height: 512,
              alt: "Arcanode",
            },
          ],
          site_name: "Arcanode",
        }}
        twitter={{
          handle: "@arcanode",
          site: "@arcanode",
          cardType: "summary_large_image",
        }}
        languageAlternates={languages.map(lang => ({
          hrefLang: lang,
          href: `${siteUrl}/${lang}${originalPath}`,
        }))}
      />

      <div className="w-full flex flex-col pt-4 justify-center items-center px-4">
        <div className="flex flex-col gap-8 py-6 max-w-md">
          <p className="text-sm uppercase">
            <Trans i18nKey="contact.header">
              Want to learn more about how Arcanode can elevate your{" "}
              <strong>digital presence?</strong>
            </Trans>
          </p>
          <p className="text-sm uppercase">
            <Trans i18nKey="contact.sub">
              <strong>Contact us</strong> to learn more.
            </Trans>
          </p>
        </div>

        <div className="max-w-sm w-full pt-8">
          <form onSubmit={handleSubmit} className="max-w-sm w-full text-white">
            <div className="mb-4">
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={t("contact.form.name")}
                className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder={t("contact.form.email")}
                className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder={t("contact.form.phone")}
                className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-6">
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder={t("contact.form.message")}
                className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                rows="5"
                required
              />
            </div>

            <div className="flex items-center justify-between pt-4">
              <button
                type="submit"
                className="border hover:bg-gray-200 hover:text-gray-700 duration-500 text-white font-bold uppercase text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {t("contact.form.button")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
